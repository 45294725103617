<template>
<div class=" kyc-config d-flex flex-col">
  <div class="d-flex justify-space-between flex-wrap">
    <span class="d-flex gap-xss align-items-center">
      <img src="../../../assets/Icons/warning.png" alt="" width="20" height="20">
      <p class="regular">برای ویرایش اطلاعات می بایست تمام فرایند احراز هویت را مجددا تکمیل نمایید</p>
    </span>
    <span class="d-flex gap-xss align-items-center">
            <img src="../../../assets/Icons/edit.svg" alt="" width="20" height="20">
      <p class="regular secondary" @click.prevent="$error('انجام شد','','success')">ویرایش و احراز هویت</p>

    </span>
  </div>
  <div class="d-flex flex-col gap-xss">
    <span class="regular">
    اطلاعات شخصی
  </span>
    <UserInfo/>
  </div>
<div class="d-flex flex-col gap-xss">
<div class="d-flex justify-space-between">
    <span class="regular">
    اطلاعات بانکی
  </span>

    <span class="d-flex gap-xss align-items-center">
            <img src="../../../assets/Icons/edit.svg" alt="" width="20" height="20">
      <p class="regular secondary" @click.prevent="$error('انجام شد','','success')">ویرایش و احراز هویت</p>

    </span>
</div>
  <BankInfo/>
</div>
</div>
</template>

<script>
import BankInfo from "@/components/Panel/KYC/BankInfo.vue";
import UserInfo from "@/components/Panel/KYC/UserInfo.vue";
export default {
  name: "KycConfig",
  components: {UserInfo, BankInfo}
}
</script>

<style lang="scss" scoped>
.kyc-config{
  gap: 36px;
}
</style>