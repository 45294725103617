<template>
  <div class="user-info">
    <input type="text"
           class="input-container"
           @input="data.firstName = $S2Fa(data.firstName)"
           placeholder="نام"
           v-model="data.firstName"
           :disabled="readonly"
    >
    <input type="text"
           class="input-container"
           placeholder="نام خانوادگی"
           @input="data.lastName = $S2Fa(data.lastName)"
           v-model="data.lastName"
           :disabled="readonly">
    <date-picker
        class="input-container"
        v-model="data.birthDay"
        :format="$dateFormat['en']"
        :display-format="$dateFormat['fa']"
        :max="$jmoment().format($dateFormat['en'])"
        :clearable="!state.userInfo.authorized"
        :disabled="readonly"
        type="date"


    />
    <input type="text" class="input-container"
           @input="data.nationalCode = $A2N(data.nationalCode)"
           placeholder="کد ملی"
           v-model="data.nationalCode"
           :disabled="readonly"
           maxlength="10"
    >
    <input type="text" class="input-container"
           @input="data.mobileNumber = $A2N(data.mobileNumber)"
           placeholder="شماره موبایل"
           v-model="data.mobileNumber"
           maxlength="11"

    >
    <input type="text" class="input-container"
           @input="data.telephone = $A2N(data.telephone)"
           placeholder="شماره ثابت"
           v-model="data.telephone"
           maxlength="11"
    >
    <input type="text"
           class="input-container address"
           placeholder="آدرس"
           id="address"
           style="resize: none"
           v-model="data.address"
    >
    <input type="text" class="input-container" placeholder="کد پستی" v-model="data.address">

    <!-- v-if="$route.path.includes('kyc')" -->


  </div>
</template>
<script>
import '../../../Styles/birthdayPicker.scss'

export default {
  name: 'UserInfo',
  props: ["data"],
  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        dob: '',
        ssn: '',
        passport: '',
        passport_expiry: '',
        passport_country: '',
        passport_state: '',
      }
    }
  },
  computed: {
    readonly() {
      return [
        "AUTHORIZED",
        "INFORMATION_CHANGED",
        "INFORMATION_CHANGED_PENDING",
      ].includes(this.state.userInfo.authenticationType);
    },
  },
}
</script>
<style lang="scss" scoped>
.user-info {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  .input-container {
    display: flex;
    align-self: center;
    background: #F0F0F0 !important;
    border-radius: 8px 8px 0px 0px !important;
    height: 48px;
    flex: 1 1 325px;
    padding: 0px 10px;
  }

  .address {
    flex: 1 1 60%;
  }
}

</style>