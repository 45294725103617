<template>
  <div class="bank-info">
    <DeleteBankModal v-if="opendelet" @close="opendelet=false" @remove="deleteBank"/>

    <div class="exist-banks">
      <div class="banks-list" v-for="(bank, index) in bankAccounts" :key="index">
        <div class="bank-number">
          {{
            bank.bankName
          }}
        </div>
        <div class="bank-number">
          <span> شماره کارت </span>
          <span>
                <input  type="text" v-model="bank.cardNumber" disabled>
          </span>
        </div>
        <div class="bank-number">
          <span> شماره شبا </span>
          <span>
            <input class="no-bg" type="text" v-model="bank.shebaCode" disabled>
          </span>
        </div>

        <img
            @click.prevent="opendelet=true"
            class="delete-icon"
            src="../../../assets/Icons/delete.png"
        />
      </div>
    </div>
    <div class="add_form">

        <input
            class="normal-input number"
            @input="dash2"
            type="text"
            v-model="bankAccount.cardNumber"
            maxlength="19"
            placeholder="شماره کارت"
        />



        <input
            class="normal-input number"

            type="text"
            maxlength="24"
            v-model="bankAccount.shebaCode"
            placeholder="شماره شبا"
        />




        <input
            class="normal-input name"
            type="text"
            placeholder=" نام بانک"
            v-model="bankAccount.bankName"
            readonly
        />


<button>
  <img
      @click="addaccount"
      class="delete-icon"
      src="../../../assets/Icons/add.png"
:disable="!bankAccount.cardNumber || !bankAccount.shebaCode"
  />
</button>
    </div>
  </div>
</template>

<script>
const banks = {
  '603799': 'بانک ملی ایران',
  '589210': 'بانک سپه',
  '627648': 'بانک توسعه صادرات',
  '207177': 'بانک توسعه صادرات',
  '627353': 'بانک تجارت',
  '585983': 'بانک تجارت',
  '627961': 'بانک صنعت و معدن',
  '603770': 'بانک کشاورزی',
  '639217': 'بانک کشاورزی',
  '628023': 'بانک مسکن',
  '627760': 'پست بانک ایران',
  '502908': 'بانک توسعه تعاون',
  '627412': 'بانک اقتصاد نوین',
  '622106': 'بانک پارسیان',
  '639194': 'بانک پارسیان',
  '627884': 'بانک پارسیان',
  '502229': 'بانک پاسارگاد',
  '639347': 'بانک پاسارگاد',
  '639599': 'بانک قوامین',
  '504706': 'بانک شهر',
  '502806': 'بانک شهر',
  '603769': 'بانک صادرات',
  '610433': 'بانک ملت',
  '991975': 'بانک ملت',
  '589463': 'بانک رفاه',
  '502938': 'بانک دی',
  '639607': 'بانک سرمایه',
  '627381': 'بانک انصار',
  '505785': 'بانک ایران زمین',
  '636214': 'بانک آینده',
  '636949': 'بانک حکمت ایرانیان',
  '621986': 'بانک سامان',
  '639346': 'بانک سینا',
  '606373': 'بانک قرض الحسنه مهر',
  '627488': 'بانک کارآفرین',
  '502910': 'بانک کارآفرین',
  '505416': 'بانک گردشگری',
  '639370': 'بانک مهر اقتصاد',
  '628157': 'موسسه اعتباری توسعه',
  '505801': 'موسسه اعتباری کوثر',
}
import DeleteBankModal from "./DeleteBankModal.vue";

export default {
  name: "BankInfo",
  props: ['data', 'error', 'change'],
  components: {
    DeleteBankModal
  },
  data() {
    return {
      bankAccounts:[{
        shebaCode: '6219861900062240',
        cardNumber: '321412355614362341',
        bankName: 'سامان'
      }],
      bankAccount: {
        shebaCode: '',
        cardNumber: '',
        bankName: ''
      },
      opendelet: false,
      bankIndex: ""
    }
  },
  methods: {
    addaccount() {
      // if (this.bankAccount.shebaCode && this.bankAccount.cardNumber) {
      //   this.bankAccounts.push(this.bankAccounts)
      //   if(this.state.userInfo.authorized){
      //     this.$emit('addBank', this.bankAccount)
      //     console.log('hi')
      //   }

      // }


      if(this.bankAccount.shebaCode && this.bankAccount.cardNumber){
        this.bankAccounts.push(this.bankAccount)
      }






    },
    removeaccount(e) {
      this.opendelet = true
      this.bankIndex = e
    },
    deleteBank() {
      this.bankAccounts.splice(this.bankIndex, 1)
      this.$emit('confirmDeleteBank')
    },
    dash(e) {
      this.bankAccounts[e].bankName = banks[this.bankAccounts[e].cardNumber ? this.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
      // this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
      this.error = ''
    },

    dash2() {
      this.bankAccount.bankName = banks[this.bankAccount.cardNumber ? this.bankAccount.cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
      this.bankAccount.cardNumber = this.$A2N(this.bankAccount.cardNumber)
      // this.bankAccount.cardNumber = this.$separator(this.bankAccount.cardNumber)
      // this.error = ''
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    // for (let [index, item] of this.data.bankAccounts.entries()) {
    //   this.dash(index)
    // }
  }
};
</script>

<style lang="scss" scoped>
.no-bg {
  background: transparent !important;
  border: 0 !important;
  height: 100%;
  width: 100%;
}

.bank-info {

  //background: #0f1640;
  //border-radius: 16px;
  width: 100%;
  min-height: 265px;
  //padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  flex-wrap: wrap;

  .add-bank {
    color: rgba(254, 188, 0, 1);
    text-align: left;
    cursor: pointer;
  }

  .add_form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  gap: 3rem;
    flex-wrap: wrap;

    input {
      display: flex;
      align-items: center;
      justify-content: space-between;


      color: var(--secondary);

      background: #F0F0F0;
      /* secondary */

      border-bottom: 2px solid #00678F;
      border-radius: 8px 8px 0px 0px;
      width: 100%;
      max-width: 298px;
      height: 48px;
      padding: 0 12px;
    }









  }
}

.exist-banks {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y:auto ;

  .banks-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .delete-icon {
      cursor: pointer;
    }

    .bank-number {
      display: flex;
      align-items: center;
      justify-content: space-between;
color: var(--secondary);



      background: #F0F0F0;
      /* secondary */

      border-bottom: 2px solid #00678F;
      border-radius: 8px 8px 0px 0px;
      width: 100%;
      max-width: 298px;
      height: 48px;
      padding: 0 12px;
    }
  }
}

@media (max-width: 1000px) {
  .add_form {
    label {
      width: 100%;
    }

    flex-direction: column;
    align-self: stretch;
    gap: 20px;

    .normal-input {
      width: 100% !important;
    }
  }
}

@media (max-width: 500px) {
  .banks-list {
    flex-direction: column;
    gap: 20px;
  }
}
</style>
