<template>
  <div class="setting d-flex flex-col">
    <OtpConfig class="content"/>
    <ChangePassword class="content"/>
    <KycConfig class="content"/>
  </div>
</template>
<script>
import OtpConfig from "@/components/Panel/Settings/OtpConfig";
import ChangePassword from "@/components/Panel/Settings/ChangePassword";
import KycConfig from "@/components/Panel/Settings/KycConfig";
export default {
  name: "Settings",
  components: {KycConfig, ChangePassword, OtpConfig}
}
</script>
<style lang="scss" scoped>
.setting{
  gap: 24px;
}
.content{
  background: #F8F8F8;
  border-radius: 12px;
  padding: 24px;
}
</style>