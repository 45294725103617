<template>
  <div class="options-config d-flex flex-col">
    <h3>شناسه دو عاملی</h3>
    <p class="regular">ﺟﻬﺖ ورود ﺑﻪ ﺣﺴﺎب ﮐﺎرﺑﺮی و درﺧﻮاﺳﺖ ﺑﺮداﺷﺖ و تغییر رمز عبور، یکی از راه های زیر را برای شناسه
      دوعاملی انتخاب کنید.</p>
    <form @change="showModal(options.findIndex(e => e.model === twoStep))" class="cont d-flex justify-space-between flex-wrap">
      <div class="radio" v-for="(options , index) in options" :key="index">
        <input type="radio" class="modal-btn" v-model="twoStep" :value="options.model"/>
        <label>
          {{ options.title }}
        </label>
      </div>
      <button class="btn-primary w100" @click.prevent="$error('تایید شد' ,'','success')">تایید</button>
    </form>

    <SMS v-if="options[1].modal" @closeModal='value=>options[1].modal=value' @submit="submitted($event)" :mobile="mobile"
              :two-step="modal"/>
    <EMAIL v-if="options[0].modal" @closeModal='value=>options[0].modal=value' @submit="submitted($event)" :qr-link="qrLink"
                :two-step="modal"
    :email="'rezaser3774@gmail.com'"
    />
    <GA v-if="options[2].modal" @closeModal='value=>options[2].modal=value' @submit="submitted($event)" :qr-code="qrCode"
    :qr-link="qrLink"
                  :two-step="modal"/>



  </div>
</template>

<script>
import GA from "@/components/Panel/Settings/modals/GA.vue";
import SMS from "@/components/Panel/Settings/modals/SMS.vue";
import  EMAIL from "@/components/Panel/Settings/modals/EMAIL.vue";
export default {
  name: "OtpConfig",
  components:{SMS,EMAIL,GA},
  data() {
    return {
      mobile:'09121234567',
      email:'rezaser@gmail.com',
      modal: '',
      twoStep:'EMAIL',
      qrLink:'asdfas',
      qrCode :'qr.png',
      options: [
        {
          title: 'ایمیل',
          model: 'EMAIL',
          modal: false,
        },
        {
          title: 'پیامک',
          model: 'SMS',
          modal: false,
        },

        {
          title: 'گوگل',
          model: 'GOOGLE_AUTHENTICATOR',
          modal: false,
        },
      ]
    }
  },
  methods: {
    async showModal(index) {
      this.options[index].modal = true


        this.state.loading = true
        const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`).catch(() => {
          this.getTwoStepAuth()
        })
        if (res) {
          this.mobile = res.baseDTO.starTwoStepAuthentication || ''
          this.qrCode = res.baseDTO.file || ''
          this.qrLink = res.baseDTO.qrCodeLink || ''
          this.options[index].modal = true
        }
      },
    },
    async getTwoStepAuth() {
      this.twoStep = this.state.userInfo.towStepAuthenticationType

    },
    async submitted(e) {
      this.completedText = e;
      await this.$error('', e, 'success')
      // await this.$getuserInfo()
      // this.getTwoStepAuth()
    },
    closeG(value) {
   
      this.options[2].modal = value
    },
    closeP(value) {
      this.options[1].modal = value
    },
    closeE(value) {
      this.options[0].modal = value
    },
  }

</script>

<style lang="scss" scoped>
.options-config {
  gap: 24px;
}
.radios{
  gap: .5rem;
}
.btn-primary{
  max-width: 162px;
  height: 44px;
}

@media(max-width: 500px){
 .cont{
   flex-direction: column;
   gap: 1rem;
 }
}
</style>